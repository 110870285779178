import "./usuarios.scss";
import http from "@/_helper/api-services.js";

export default {
    name: "usuarios",
    data() {
        return {
            nomeLogin: '',
            search: {
                login: String(),
                nome: String(),
                email: String(),
                idPais: null,
                ativo: true,
                quantidade: 25,
                page: 1
            },
            fields: [{ key: "nome", label: "Nome", sortable: true, sortDirection: "desc" },
            { key: "login", label: "Login", sortable: true },
            { key: "email", label: "Email", sortable: true, class: 'text-left title-color ' },
            { key: "pais", label: "País", sortable: true, class: 'text-left title-color ' },
            { key: "bloqueado", label: "Bloqueado", sortable: true },
            { key: "saque", label: "Saque bloqueado", sortable: true },
            { key: "saqueRede", label: "Saque rede bloqueado", sortable: true },
            { key: "saqueRendimento", label: "Saque rendimento bloqueado", sortable: true },
            { key: "ativo", label: "Ativo", sortable: true },
            // { key: "taxaSaque", label: "Taxa de saque", sortable: true, formatter: value => { return (value ? value : 0) + "%" } },
            { key: "opcoes", label: "Opções" }
            ],
            history: [],
            currentPage: 1,
            totalPaginas: 1,
            totalRows:0,
            perPage: 25,
            pageOptions: [25, 50, 100],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            usuarioConfiguracao: {
                idUsuarioConfiguracao: 0,
                nomeUsuario: String(),
                idUsuario: String(),
                taxaSaque: Number(),
                emailConfirmado: Boolean(),
                carteiras: []
            },
            link: '',
            showIconsToChangePage: false,
            listaPaises: [{ text: "Selecione o país", value: null }]
        };
    },
    components: {
    },
    computed: {
        totalPagesToDisplay() {
            if (this.totalPaginas <= 5) {
                this.showIconsToChangePage = false;
                return this.totalPaginas;
            } else {
                this.showIconsToChangePage = true;

                let paginasSobrando = this.totalPaginas - this.search.page;

                if (paginasSobrando == 0) {
                    let retorno = [];
                    for (let i = this.totalPaginas - 2; i <= this.totalPaginas; i++)
                        retorno.push(i);
                    return retorno;
                } else if (paginasSobrando == this.totalPaginas - 1) return [1, 2, 3];
                else return [this.search.page - 1, this.search.page, this.search.page + 1];
            }
        }
    },
    beforeMount() {
        this.listarPaises();
        this.searchUsers();
    },
    watch: {
       
    },
    mounted() {
        //this.obterNomeLogado();
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        listarPaises() {
            http.get("/general/getCountries").then(
                success => { 
                    if (success.data) {
                        success.data.forEach(item => {
                            this.listaPaises.push({
                                text: item.nome,
                                value: item.idPais
                            });
                        });
                    }
                },
                error => {
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            )

        },
        //paisSelecionado(idPais) {
            
        //}

        async searchUsersClick(event) {
            this.search.page = 1;
            this.searchUsers(event);
        },
        async searchUsers(event) {
            if (event) event.preventDefault();

            //if (!this.search.login && !this.search.email) {
            //    this.$snotify.error("Enter login or email to filter");

            //    return;
            //}
            this.$loading(true);
            await http.post("/admin/filterUsers", this.search)
                .then(
                    success => {
                        this.history = [];
                        if (success && success.data) {
                            this.totalPaginas = success.data.totalPages;
                            success.data.listuser.forEach(d => {
                                this.history.push({
                                    nome: d.nome,
                                    login: d.login,
                                    email: d.email,
                                    pais: d.pais,
                                    paisIso: d.paisIso,
                                    emailConfirmado: d.emailConfirmado,
                                    taxaSaque: d.taxaSaque,
                                    idUsuario: d.idUsuario,
                                    ativo: d.ativo,
                                    bloqueado: d.bloqueado,
                                    doisFatores: d.doisFatores,
                                    idGrupo: d.idGrupo,
                                    saque: d.saque,
                                    saqueRede: d.saqueRede,
                                    saqueRendimento: d.saqueRendimento
                                });
                            });
                            //this.totalRows = success.data.length;
                            this.$loading(false);
                        }
                    },
                    error => {
                        this.$loading(false);
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    }
                );
        },
        efetuarLoginRemoto(idUsuario) {
            this.$loading(true);
            http.get(`/admin/performRemoteAccess/${idUsuario}`).then(
                (success) => {
                    localStorage.removeItem("menu");
                    localStorage.setItem(
                        "userAdmin",
                        localStorage.getItem("user")
                    );
                    localStorage.setItem("user", JSON.stringify(success.data));
                    this.$router.push("/");
                    window.location.reload();
                },
                (error) => {
                    if (error.response && error.response.data) {
                        if (error.response.data.errors) {
                            error.response.data.errors.forEach((e) => {
                                this.$snotify.error(e);
                            });
                        } else if (error.response.data.errors) this.$snotify.error(error.response.data.message);
                        else if (error.response.data) this.$snotify.error(error.response.data);
                    } else this.$snotify.error("Erro ao encontrar o usuario");
                    this.$loading(false);
                }
            );
        },
        openModalEditarTaxa(idUsuario) {
            this.$root.$emit('bv::hide::popover');
            var usuario = this.history.filter(f => f.idUsuario == idUsuario)[0];

            this.usuarioConfiguracao.nomeUsuario = usuario.nome;
            this.usuarioConfiguracao.idUsuario = usuario.idUsuario;
            this.usuarioConfiguracao.taxaSaque = usuario.taxaSaque;
            this.usuarioConfiguracao.emailConfirmado = usuario.emailConfirmado;

            this.$bvModal.show('modal-editar-taxa');
        },
        salvarTaxaSaque() {
            this.$loading(true);

            this.usuarioConfiguracao.taxaSaque = parseFloat(this.usuarioConfiguracao.taxaSaque);

            http.update("/admin/changeRateWithdrawal", this.usuarioConfiguracao).then((response) => {
                this.history.filter(f => f.idUsuario == this.usuarioConfiguracao.idUsuario)[0].taxaSaque = this.usuarioConfiguracao.taxaSaque;
                this.$snotify.success("Taxa de saque alterada com sucesso!");

                this.$bvModal.hide('modal-editar-taxa');
            }, error => {
                if (error.response && typeof error.response.data == "object")
                    this.$snotify.error(error.response.data.message);
                
            }).finally(() => {
                this.$loading(false);
            });
        },
        validaTaxaSaque() {
            if (!this.usuarioConfiguracao.taxaSaque || this.usuarioConfiguracao.taxaSaque < 0 || this.usuarioConfiguracao.taxaSaque > 100) {
                return false;
            }

            this.salvarTaxaSaque();
        },
        alterarPagina(pagina) {
            this.search.page = pagina;
            this.searchUsers();
        },
        //redefinirSenhaAcesso(idUsuario) {
        //    this.$loading(true);
        //    http.get("/admin/redefinirSenhaUsuairo/" + idUsuario).then(
        //        success => {
        //            if (success.data) {
        //                this.opemModalLinkRedefinicaoSenha(success.data)
        //            }
        //        },
        //        error => {
        //            if (error.response && error.response.data)
        //                this.$snotify.error(error.response.data.message);
        //        }
        //    ).finally(() => {
        //        this.$loading(false);
        //    });
        //},
        //redefinirSenhaAcesso(idUsuario) {

        //    this.$loading(true);
        //    http.get("/admin/redefinirSenhaUsuairo/" + idUsuario).then(
        //        success => {
        //            if (success.data) {
        //                this.openModalAprovacao(success.data)
        //            }
        //        },
        //        error => {
        //            if (error.response && error.response.data)
        //                this.$snotify.error(error.response.data.message);
        //        }
        //    ).finally(() => {
        //        this.$loading(false);
        //    });
        //},
        authFactor(idUsuario) {
            this.$loading(true);
            http.get("/admin/authFactor/" + idUsuario).then(
                success => {
                    if (success.data) {
                        this.searchUsers(event);
                        this.$snotify.success("Autenticação 2 fatores desabilitada");
                    } else {
                        this.$snotify.error("Erro ao desabilitar autenticação 2 fatores!");
                    }
                },
                error => {
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            ).finally(() => {
                this.$loading(false);
            });
        },
        opemModalLinkRedefinicaoSenha(link) {
            this.$root.$emit('bv::hide::popover');
            this.link = link
            this.$bvModal.show('modal-redefinir-senha');
        },
        close() {
            this.$bvModal.hide('modal-redefinir-senha');
        },
        bloquearUsuario(idUsuario, bloqueado) {
            this.$loading(true);
            http.get("/admin/blockUserAdmin/" + idUsuario).then(
                success => {
                    if (success.data) {
                        this.searchUsers(event);
                        if (!bloqueado)
                            this.$snotify.success("Usuário Bloqueado");
                        else
                            this.$snotify.success("Usuário Desbloqueado");
                    } else {
                        this.$snotify.error("Erro ao bloquear o usuário!");
                    }
                },
                error => {
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            ).finally(() => {
                this.$loading(false);
            });
        },
        bloquearSaqueUsuario(idUsuario, saque) {
            this.$loading(true);
            http.get("/admin/blockWithdrawUserAdmin/" + idUsuario).then(
                success => {
                    if (success.data) {
                        this.searchUsers(event);
                        if (saque)
                            this.$snotify.success("Saque Bloqueado");
                        else
                            this.$snotify.success("Saque Desbloqueado");
                    } else {
                        this.$snotify.error("Erro ao bloquear o saque!");
                    }
                },
                error => {
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            ).finally(() => {
                this.$loading(false);
            });
        },
        bloquearSaqueRedeUsuario(idUsuario, saque) {
            this.$loading(true);
            http.get("/admin/blockNetworkWithdrawUserAdmin/" + idUsuario).then(
                success => {
                    if (success.data) {
                        this.searchUsers(event);
                        if (saque)
                            this.$snotify.success("Saque Rede Bloqueado");
                        else
                            this.$snotify.success("Saque Rede Desbloqueado");
                    } else {
                        this.$snotify.error("Erro ao bloquear o saque rede!");
                    }
                },
                error => {
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            ).finally(() => {
                this.$loading(false);
            });
        },
        bloquearSaqueRendimentoUsuario(idUsuario, saque) {
            this.$loading(true);
            http.get("/admin/blockIncomeWithdrawUserAdmin/" + idUsuario).then(
                success => {
                    if (success.data) {
                        this.searchUsers(event);
                        if (saque)
                            this.$snotify.success("Saque Rendimento Bloqueado");
                        else
                            this.$snotify.success("Saque Rendimento Desbloqueado");
                    } else {
                        this.$snotify.error("Erro ao bloquear o saque rendimento!");
                    }
                },
                error => {
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            ).finally(() => {
                this.$loading(false);
            });
        },
        inativarUsuario(idUsuario, ativo) {
            this.$loading(true);
            http.get("/admin/inactivateUserAdmin/" + idUsuario).then(
                success => {
                    if (success.data) {
                        this.searchUsers(event);
                        if (!ativo)
                            this.$snotify.success("Usuário ativado");
                        else
                            this.$snotify.success("Usuário inativado");
                    } else {
                        this.$snotify.error("Erro ao bloquear o usuário!");
                    }
                },
                error => {
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            ).finally(() => {
                this.$loading(false);
            });
        },

        //openModalAprovacao(idUsuario) {
        //    this.$snotify.error("This function was temporarily disable.");
        //    return;
        //    var usuario = this.history.filter(f => f.idUsuario == idUsuario)[0];
        //    this.$root.$emit('bv::hide::popover');

        //    http.get("/usuarioCarteira/obterUsuarioCarteiraAdmin/" + idUsuario).then(
        //        success => {
        //            if (success.data) {
        //                this.usuarioConfiguracao.carteiras = success.data;
        //            } else {
        //                this.$snotify.error("Erro ao confirmar email");
        //            }
        //        },
        //        error => {
        //            if (error.response && error.response.data)
        //                this.$snotify.error(error.response.data.message);
        //        }
        //    ).finally(() => {
        //        this.$loading(false);
        //    });

        //    this.usuarioConfiguracao.idUsuario = usuario.idUsuario;
        //    this.usuarioConfiguracao.emailConfirmado = usuario.emailConfirmado;

        //    this.$bvModal.show('modal-aprovacao');
        //},
        close() {
            this.$bvModal.hide('modal-aprovacao');
        },

        confirmarEmail(idUsuario) {
            this.$loading(true);
            http.post("/admin/confirmEmailAdmin/" + idUsuario).then(
                success => {
                    if (success.data) {
                        this.$snotify.success("Email confirmado com sucesso");
                        this.searchUsers();
                    } else {
                        this.$snotify.error("Erro ao confirmar email");
                    }
                },
                error => {
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            ).finally(() => {
                this.$loading(false);
            });
        },

        //aprovarCarteira(idUsuarioCarteira) {
        //    this.$loading(true);
        //    http.post("/admin/aprovarCarteiraAdmin/" + idUsuarioCarteira).then(
        //        success => {
        //            this.$snotify.success("Carteira aprovada com sucesso");
        //        },
        //        error => {
        //            if (error.response && error.response.data)
        //                this.$snotify.error(error.response.data.message);
        //        }
        //    ).finally(() => {
        //        this.$loading(false);
        //    });
        //},
    }
}