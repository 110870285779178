import http from '@/_helper/api-services'
import './SureBet.scss'
import SideBar from "@/components/SideBar/SideBar.vue";
import PremiosDashboard from '@/components/PremiosDashboard/PremiosDashboard.vue';
import DatePicker from 'vue2-datepicker';
import CardInvestimento from '@/components/CardInvestimento/CardInvestimento.vue';

export default {
    name: 'SureBet',
    data() {
        return {
            search: {
                homeTeam: String(),
                awayTeam: String(),
                dataInicio: this.dataMenos30(),
                tipoProduto: 1,
                dataFim: new Date(),
                quantidade: 10,
                page: 1
            },
            totalPaginas: 1,
            pageOptions: [10, 50, 100],
            listaProdutos:[
                {value: 1, text: this.$i18n.t("generico.pacote")},
                {value: 2, text: this.$i18n.t("generico.pacote")+" Premium"},
                {value: 3, text: this.$i18n.t("generico.pacote")+" VIP"}
            ],
            contratos:[],
            show: true,
            showIconsToChangePage: false,
            lang: {
                days: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                months: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez",],
            }
        }
    },
    components: {
        SideBar,
        PremiosDashboard,
        DatePicker,
        CardInvestimento
    },
    computed: {
        totalPagesToDisplay() {
            if (this.totalPaginas <= 5) {
                this.showIconsToChangePage = false;
                return this.totalPaginas;
            } else {
                this.showIconsToChangePage = true;

                let paginasSobrando = this.totalPaginas - this.search.page;

                if (paginasSobrando == 0) {
                    let retorno = [];
                    for (let i = this.totalPaginas - 2; i <= this.totalPaginas; i++)
                        retorno.push(i);
                    return retorno;
                } else if (paginasSobrando == this.totalPaginas - 1) return [1, 2, 3];
                else return [this.search.page - 1, this.search.page, this.search.page + 1];
            }
        }
    },
    beforeMount(){
        this.getMyContracts();
    },
    methods:{
         searchUsersClick() {
            this.search.page = 1;
            this.getMyContracts();
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        alterarPagina(pagina) {
            this.search.page = pagina;
            this.getMyContracts();
        },
        getMyContracts(){
            this.$loading(true);
            http.post("/jogo/getMyContracts", this.search).then((response) => {
                this.totalPaginas = response.data.totalPages;
                this.contratos = response.data.lista;

                if(!this.contratos.length)
                    this.show = false;
                else
                    this.show = true;
            },error => {
                this.errorMessage = error.response.data;
                this.$snotify.error(this.errorMessage);
            }).finally(() => {
                this.$loading(false);
            });
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
            }
        },
        formatPrice(valor) {
            if(!valor)
                return '$0,00';
            
            return valor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
          });
        },
    }
}